import React from 'react';
import '../scss/molecules/_lightbox-popup.scss';

class LightboxMovie extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };

    this.onKeyPressed = this.onKeyPressed.bind(this);
  }
  open() {
    this.setState({ active: true });
  }
  close() {
    this.setState({ active: false });
  }
  onKeyPressed(e) {
    if (e.key === 'Escape') {
      this.close();
    }
  }
  componentWillMount() {
    if (typeof window !== 'undefined') {
      document.addEventListener('keydown', this.onKeyPressed);
    }
  }
  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('keydown', this.onKeyPressed);
    }
  }
  render() {
    let lightboxPopup = '';
    if (this.state.active) {
      lightboxPopup = (
        <div
          className="lightbox-popup"
          onKeyPress={e => this.onKeyPressed(e)}
          tabIndex="0"
        >
          <div
            className="lightbox-popup-container"
            onClick={() => {
              this.close();
            }}
          >
            <div className="lightbox-popup-media">
              <div className="lightbox-popup-media-video">
                <iframe
                  id="ytplayer"
                  type="text/html"
                  width="640"
                  height="360"
                  src={
                    'https://www.youtube.com/embed/' +
                    this.props.videoId +
                    '?autoplay=1'
                  }
                  frameBorder="0"
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
    {
      lightboxPopup;
    }

    return (
      <>
        <a
          href={this.props.videoSrc}
          onClick={e => {
            e.preventDefault();
            this.open();
          }}
        >
          <img src={this.props.imageSrc} alt={this.props.imageTitle} />
        </a>
        {lightboxPopup}
      </>
    );
  }
}

export default LightboxMovie;
