import React from 'react';
import '../scss/molecules/_lightbox-popup.scss';

class LightboxGallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: -1,
    };

    this.onKeyPressed = this.onKeyPressed.bind(this);
  }
  setActive(index) {
    this.setState({ active: index });
  }
  increaseActive() {
    let newIndex = this.state.active + 1;
    if (newIndex < 0) {
      newIndex = this.props.images.length - 1;
    } else if (newIndex >= this.props.images.length) {
      newIndex = 0;
    }
    this.setActive(newIndex);
  }
  decreaseActive() {
    let newIndex = this.state.active - 1;
    if (newIndex < 0) {
      newIndex = this.props.images.length - 1;
    } else if (newIndex >= this.props.images.length) {
      newIndex = 0;
    }
    this.setActive(newIndex);
  }
  close() {
    this.setState({ active: -1 });
  }
  onKeyPressed(e) {
    if (e.key === 'ArrowLeft') {
      this.decreaseActive();
    } else if (e.key === 'ArrowRight') {
      this.increaseActive();
    } else if (e.key === 'Escape') {
      this.close();
    }
  }
  componentWillMount() {
    if (typeof window !== 'undefined') {
      document.addEventListener('keydown', this.onKeyPressed);
    }
  }
  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('keydown', this.onKeyPressed);
    }
  }
  render() {
    let lightboxPopup = '';
    if (this.state.active !== -1) {
      lightboxPopup = (
        <div
          className="lightbox-popup"
          onKeyPress={e => this.onKeyPressed(e)}
          tabIndex="0"
        >
          <div
            className="lightbox-popup-container"
            onClick={() => {
              this.close();
            }}
          >
            <div
              className="lightbox-popup-media"
              onClick={e => {
                e.preventDefault();
                this.increaseActive();
              }}
            >
              <img
                src={this.props.images[this.state.active].url}
                title={this.props.images[this.state.active].title}
              />
              <div className="lightbox-popup-footer">
                <p className="lightbox-popup-caption">
                  {this.props.images[this.state.active].title}
                </p>
                <p className="lightbox-popup-count">
                  {this.state.active + 1}/{this.props.images.length}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="lightbox-gallery">
          <div className="row">
            {this.props.images.map((x, i) => {
              return (
                <div className="col-xs-6 col-sm-4 col-md-3" key={x.url}>
                  <div className="item">
                    <a
                      href={x.url}
                      title={x.title}
                      onClick={e => {
                        this.setActive(i);
                        e.preventDefault();
                      }}
                    >
                      <img src={x.thumb} alt={x.title} />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {lightboxPopup}
      </>
    );
  }
}

export default LightboxGallery;
