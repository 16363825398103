import React from 'react';

import Layout from '../components/layout';
import Nav from '../components/nav';
import Footer from '../components/footer';
import HeaderMovie from '../components/headermovie';
import Contact from '../components/contact';
import SEO from '../components/seo';
import LightboxGallery from '../components/lightboxgallery';

import '../scss/attorno.scss';

const WaaromAttorno = () => (
  <Layout>
    <SEO
      title="Media"
      description="Ontdek onze foto's. Onze pizza ovens worden authentiek gestookt met hout. Dit zorgt voor de overheerlijke smaak van de pizza. De ovens zijn geïsoleerd en dus veilig voor uw kinderen. Ons kraam past perfect in Uw tuin. Elegant met zijn houten afwerking en op 15 minuten opgesteld. Vanaf dan kunnen wij Uw pizzafeest verzorgen met de hoogste kwaliteit in acht."
    />

    <Nav />

    <HeaderMovie />

    <section
      className="pattern-bg pt-5 pb-8"
      style={{ backgroundImage: 'url(/design/img/pattern-background.png)' }}
    >
      <div className="container gallery">
        <h1 className="h2 title text-center">Foto's</h1>
        <LightboxGallery
          images={[
            {
              url: '/design/img/media/Photo 30-04-16 17 23 04.jpg',
              thumb: '/design/img/media/thumbs/Photo 30-04-16 17 23 04.jpg',
              title:
                'Wij komen op alle locaties, kleine tuin of grote wei. Overal kunt U proeven van onze overheerlijke pizza’s à volonté. Een ideaal gedacht voor Uw trouwfeest en het communiefeest van Uw kinderen..',
            },
            {
              url: '/design/img/media/Pizzakraam.jpg',
              thumb: '/design/img/media/thumbs/Pizzakraam.jpg',
              title:
                'Bij prachtig weer staat ons kraam opgesteld zo dicht mogelijk bij de mensen buiten. Is er toch regen? Geen probleem! Onze tafel kan binnen geplaatst worden zodat Uw feest ongestoord door kan gaan.',
            },
            {
              url: '/design/img/media-2019/Attorno-130.jpg',
              thumb: '/design/img/media-2019/thumbs/Attorno-130.jpg',
              title:
                'Vlees liefhebber? Geniet van onze verschillende soorten pizza’s met dagvers vlees uit de keurslager. Geen vlees  liefhebber? Wij hebben tal van vegetarische pizza’s en pizza’s met vis. Ons doel is iedereen te kunnen bekoren!',
            },
            {
              url: '/design/img/media-2019/Attorno-32.jpg',
              thumb: '/design/img/media-2019/thumbs/Attorno-32.jpg',
              title:
                'Klein feest? Groot feest? Geen enkel probleem! Attorno komt Uw feest tot in de puntjes verzorgen vanaf 15 personen. Lekkere pizza aan huis à volonté. Wij brengen meer dan 12 verschillende soorten pizza naar ieders wil.',
            },
            {
              url: '/design/img/media-2019/Attorno-593_web.jpg',
              thumb: '/design/img/media-2019/thumbs/Attorno-593_web.jpg',
              title:
                'Onze pizza ovens worden authentiek gestookt met hout. Dit zorgt voor de overheerlijke smaak van de pizza. De ovens zijn geïsoleerd en dus veilig voor uw kinderen.',
            },
            {
              url: '/design/img/media-2019/Attorno-176_web.jpg',
              thumb: '/design/img/media-2019/thumbs/Attorno-176_web.jpg',
              title:
                'Onze vriendelijke en spontane bediening zullen samen met U ervoor zorgen dat Uw gasten een pizza festijn kunnen beleven. In samenspraak beslissen wij ter plaatste met U hoe wij U nog beter kunnen helpen.',
            },
            {
              url: '/design/img/media-2019/Attorno-385_web.jpg',
              thumb: '/design/img/media-2019/thumbs/Attorno-385_web.jpg',
              title:
                'Buiten pizza’s aan huis kunnen wij ook voorgerechtjes en desserts leveren. Dit zorgt mee voor het totale Italiaanse gevoel rond Uw feest. Vraag zeker onze opties hieromtrent.',
            },
            {
              url: '/design/img/media-2019/Attorno-667_web-2.jpg',
              thumb: '/design/img/media-2019/thumbs/Attorno-667_web-2.jpg',
              title: '',
            },
            {
              url: '/design/img/media-2019/Attorno-195_web.jpg',
              thumb: '/design/img/media-2019/thumbs/Attorno-195_web.jpg',
              title:
                'Wij hebben een uitgebreid assortiment aan exquisiete pizza’s die U zeker zullen smaken. Deze worden allemaal belegd op het feest zodat U ook kan meegenieten van onze voorbereiding. Laat ons zeker op voorhand allergieën weten, dan houden wij daar voor U rekening voor.',
            },
            {
              url: '/design/img/media-2019/Attorno-482_web.jpg',
              thumb: '/design/img/media-2019/thumbs/Attorno-482_web.jpg',
              title:
                'Attorno, ambachtelijke pizza’s. Met onze mobiele pizza oven kunnen wij tot overal komen. 75cm is de totale breedte die we nodig hebben om met onze hout gestookte ovens. Een pizza feest met culinaire smaak. Daar staan wij voor.',
            },
            {
              url: '/design/img/media-2019/Attorno_8889.jpg',
              thumb: '/design/img/media-2019/thumbs/Attorno_8889.jpg',
              title: '',
            },
            {
              url: '/design/img/media-2019/Attorno-199_web.jpg',
              thumb: '/design/img/media-2019/thumbs/Attorno-199_web.jpg',
              title: '',
            },
            {
              url: '/design/img/media-2019/Attorno-528_web.jpg',
              thumb: '/design/img/media-2019/thumbs/Attorno-528_web.jpg',
              title:
                'Voor de mensen waarvoor de mogelijkheid van vlees wegvalt, hebben wij een ruim assortiment aan vegetarische pizza’s. Wij kunnen ook voorzien in lactosevrije kaas en glutenvrije bodems. Dit alles moet U ons maar vragen. Ook in halal zijn de mogelijkheden oneindig.',
            },
            {
              url: '/design/img/media-2019/Attorno-549_web.jpg',
              thumb: '/design/img/media-2019/thumbs/Attorno-549_web.jpg',
              title:
                'Onze zelf ontworpen ovens zorgen voor het cachet dat Uw feest verdient. Eens apart doen? Origineel zijn? Boek Attorno voor Uw feest.',
            },
            {
              url: '/design/img/media-2019/Attorno-229_web.jpg',
              thumb: '/design/img/media-2019/thumbs/Attorno-229_web.jpg',
              title:
                'Op onze tafel worden de pizza’s in zes punten gesneden voor U. Zo kunt U van alle pizza’s een punt proeven als U dat wenst. Attorno zorgt voor een service op maat naar Uw wensen. Spreek ons hierover zeker aan. Bij Attorno is de klant koning.',
            },
            {
              url: '/design/img/media/Photo 06-05-16 14 07 00.jpg',
              thumb: '/design/img/media/thumbs/Photo 06-05-16 14 07 00.jpg',
              title:
                'Ons kraam past perfect in Uw tuin. Elegant met zijn houten afwerking en op 15 minuten opgesteld. Vanaf dan kunnen wij Uw pizzafeest verzorgen met de hoogste kwaliteit in acht.',
            },
            {
              url: '/design/img/media/Pizza dagvers.jpg',
              thumb: '/design/img/media/thumbs/Pizza dagvers.jpg',
              title:
                'Onze overheerlijke pizza met Italiaanse ham kan iedereen bekoren. De pizza wordt belegd voor Uw ogen met dagverse ingrediënten. Dat zorgt voor een ultieme ervaring op Uw pizzafeest.',
            },
            {
              url: '/design/img/media/Pizza hawaii.jpg',
              thumb: '/design/img/media/thumbs/Pizza hawaii.jpg',
              title:
                'Ons aanbod bestaat uit pizza’s geschikt voor elk feest. De meer klassieke pizza’s waarvan de kleine kinderen smullen.',
            },
            {
              url: '/design/img/media/Tradiotionele houtoven.jpg',
              thumb: '/design/img/media/thumbs/Tradiotionele houtoven.jpg',
              title:
                'Het concept zorgt ervoor dat Uw gelegenheid op een leuke informele manier kan gebeuren. Gasten hoeven niet aan de tafel te blijven zitten, maar kunnen een lekker stukje pizza komen halen aan onze tafel. Dit zoveel ze wensen, tot anderhalf uur lang.',
            },
          ]}
        />
      </div>
    </section>

    <Contact dark="true" />

    <Footer />
  </Layout>
);

export default WaaromAttorno;
