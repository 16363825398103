import React from 'react';
import LightboxMovie from './lightboxmovie';

const HeaderMovie = () => (
  <header className="intro-block py-0 cover-bg">
    <div className="header-movie mfp-iframe">
      <div className="header-movie-overlay">
        <div className="container text-center">
          <p className="h1 title mt-3">Bekijk zeker onze promofilm</p>
        </div>
      </div>
      <LightboxMovie
        imageSrc="/design/img/media/thumbs/video-thumb.jpg"
        imageTitle="Onze company movie, ontdek ons mobiel pizzakraam in actie"
        videoId="xXV9ZQfPivU"
        videoSrc="//www.youtube.com/watch?v=xXV9ZQfPivU"
      />
    </div>
  </header>
);

export default HeaderMovie;
